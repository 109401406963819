import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './_helpers/httpconfig.interceptor';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { CupsRequestComponent } from './components/cups-request/cups-request.component';
import { DynamicGridComponent } from './components/dynamic-grid/dynamic-grid.component';
import { CupsRequestInfoComponent } from './components/cups-request-info/cups-request-info.component';
import { PmCupsRequestComponent } from './components/pm-cups-request/pm-cups-request.component';
import { NumbersOnlyDirective } from 'src/services/directives/numbers-only.directive';
import { DragDropDirective } from 'src/services/directives/drag-drop.directive';
import { LineSeparatorDirective } from 'src/services/directives/line-separator.directive';
import { DistributorRequestComponent } from './components/admin/distributor/distributor-request/distributor-request.component';
import { UserRegistrationComponent } from './components/admin/user/user-registration/user-registration.component';
import { UserGridComponent } from './components/admin/user/user-grid/user-grid.component';
import { AdminComponent } from './components/admin/admin.component';
import { RebatesComponent } from './components/rebates/rebates.component';
import { EvemRequestComponent } from './components/rebates/evem-request/evem-request.component';
import { PmRebateRequestComponent } from './components/rebates/pm-rebate-request/pm-rebate-request.component';
import { RebateInfoComponent } from './components/rebates/rebate-info/rebate-info.component';
import { DistributorGridComponent } from './components/admin/distributor/distributor-grid/distributor-grid.component';
import { DistributorViewComponent } from './components/admin/distributor/distributor-view/distributor-view.component';
import { ApprovalConfigComponent } from './components/admin/approval-config/approval-config.component';
import { CupRequestTabComponent } from './components/cup-request-tab/cup-request-tab.component';
import { SamlComponent } from './components/saml/saml.component';
import { LoginComponent } from './components/login/login.component';
import { AuthenticationGuard } from './authentication.guard';
import { ErrorComponent } from './components/error/error.component';
import { LoginTestComponent } from './components/login-test/login-test.component';
import { SalesSupportComponent } from './components/admin/sales-support/sales-support/sales-support.component';
import { SalesCommericalManagerComponent } from './components/admin/sales-commerical-manager/sales-commerical-manager.component';
import { ProductManagerComponent } from './components/admin/product-manager/product-manager.component';
import { CupHistoryComponent } from './components/cup-history/cup-history.component';
import { PriceHistoryRevisionComponent } from './components/admin/price-history-revision/price-history-revision.component';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChipsModule } from 'primeng/chips';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { RebateShippedFormComponent } from './components/rebate-shipped-form/rebate-shipped-form.component';
import { AccountDetailsComponent } from './components/admin/account-details/account-details.component';
import { CustomCurrencyPipe } from 'src/services/pipes/custom-currency.pipe';
import { RemoveCommaPipe } from 'src/services/pipes/remove-comma.pipe';
import { SupportRequestGridComponent } from './components/support-request-grid/support-request-grid.component';
import { EndmarketSalesReportComponent } from './components/admin/endmarket-sales-report/endmarket-sales-report.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CupsRequestComponent,
    DynamicGridComponent,
    SupportRequestGridComponent,
    CupsRequestInfoComponent,
    PmCupsRequestComponent,
    NumbersOnlyDirective,
    DragDropDirective,
    LineSeparatorDirective,
    DistributorRequestComponent,
    UserRegistrationComponent,
    UserGridComponent,
    AdminComponent,
    RebatesComponent,
    EvemRequestComponent,
    PmRebateRequestComponent,
    RebateInfoComponent,
    DistributorGridComponent,
    DistributorViewComponent,
    ApprovalConfigComponent,
    CupRequestTabComponent,
    SamlComponent,
    LoginComponent,
    ErrorComponent,
    LoginTestComponent,
    SalesSupportComponent,
    SalesCommericalManagerComponent,
    ProductManagerComponent,
    CupHistoryComponent,
    PriceHistoryRevisionComponent,
    RebateShippedFormComponent,
    AccountDetailsComponent,
    EndmarketSalesReportComponent,
    CustomCurrencyPipe,
    RemoveCommaPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    ToastrModule,
    NgxSpinnerModule,
    MultiSelectModule,
    AutoCompleteModule,
    TableModule,
    DropdownModule,
    ConfirmDialogModule,
    CalendarModule,
    TabViewModule,
    CheckboxModule,
    FileUploadModule,
    DialogModule,
    FieldsetModule,
    OverlayPanelModule,
    RadioButtonModule,
    AccordionModule,
    CardModule,
    InputSwitchModule,
    ChipsModule,
    TooltipModule,
    CommonModule,
    RouterModule.forRoot([
      { path: '', pathMatch: 'full', component: LoginComponent },
      { path: 'saml', pathMatch: 'full', component: SamlComponent },
      { path: 'home', pathMatch: 'full', component: HomeComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'home' } },
      { path: 'cups-request', component: CupsRequestComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'cups-request' } },
      { path: 'cups-request/info', component: CupRequestTabComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'cups-request/info' } },
      { path: 'pm-cups-request', component: PmCupsRequestComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'pm-cups-request' } },
      { path: 'distributor-request', component: DistributorRequestComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'distributor-request' } },
      { path: 'users', component: UserGridComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'users' } },
      { path: 'admin', component: AdminComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'admin' } },
      { path: 'rebates', component: RebatesComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rebates' } },
      { path: 'price-history-revision', component: PriceHistoryRevisionComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'price-history-revision' } },
      { path: 'rebates/evem-rebate-form', component: EvemRequestComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rebates/evem-rebate-form' } },
      { path: 'rebates/pm-rebate-form', component: PmRebateRequestComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rebates/pm-rebate-form' } },
      { path: 'rebates/rebate-info', component: RebateInfoComponent, canActivate: [AuthenticationGuard], data: { expectedurl: 'rebates/rebate-info' } },
      { path: 'error', component: ErrorComponent },
      // { path: 'login-test', pathMatch: 'full', component: LoginTestComponent}

    ], { useHash: true, onSameUrlNavigation: 'reload' })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    CookieService,

    ConfirmationService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
