import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-cups-request-info',
  templateUrl: './cups-request-info.component.html',
  styleUrls: ['./cups-request-info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CupsRequestInfoComponent implements OnInit {

  @Input() cupReqData;
  @Output() sendCupDetails = new EventEmitter();
  formData = {};
  orgRowData: any;
  rowData: any;
  labelData: any;
  productCupHistoryCols: any;
  productCupHistoryData: any;
  endCustomerProductHistoryCols: any;
  endCustomerProductHistoryData: any;
  marketSalesCols: any;
  marketSalesData: any;
  defaultWidth = '100%';
  approvalHistoryAPI: any;
  reqForm = {};
  headerName = "";
  dispEVEMDailog = false;
  statusOfCup = "";
  displayBtns = false;
  workFlowResp: any;
  userId: any;
  apiResp: any;
  cupApprovalStatus: any;
  businessId: any;
  dispCounterOffer: boolean = false;
  rejectWithCounterOfferData: any;
  rwcApiResp: any
  dispApprovalHistory: boolean = false;
  approvalHistoryData: any;
  approvalHistoryCols = [
    { field: "date", form_name: "", header: "Date", header_id: 2, is_editable: false, is_grid_display: true, is_show: true, serial_no: 1 },
    { field: "status_name", form_name: "", header: "Status", header_id: 1, is_editable: false, is_grid_display: true, is_show: true, serial_no: 1 },
    { field: "approvar_name", form_name: "", header: "Name", header_id: 3, is_editable: false, is_grid_display: true, is_show: true, serial_no: 1 },
    { field: "approvar_comments", form_name: "", header: "Comments", header_id: 4, is_editable: false, is_grid_display: true, is_show: true, serial_no: 1 }
  ];
  isDistributor: boolean = false;
  dispDistributorRespBtns: boolean = false;
  distributorUsers: any;
  duApiResp: any;
  dispActivateDialog: boolean = false;
  activateForm = {};
  isSalesSupportLogin: boolean = false;
  salesSupportUsers: any;
  currentDate: any;
  approvers: any;
  toApprovers: any;
  fromApprovers: any;
  displayDelgationPopup: boolean = false;
  userAdmin: boolean = false;
  delegationRequest = {};
  selectedFromApprover: any;
  selectedToApprover: any;
  displayComments: boolean = false;
  headerComments = '';
  newComments = '';
  internalComments: boolean = false;
  editComments: boolean = false;
  labelCount = 0;
  leftColumns: any;
  rightColumns: any;
  halfWayIndex: any;
  commentsList: any;
  internalCommentsList: any;
  externalCommentsList: any;
  dispEMExpiryUpdateDialog: boolean = false;
  updatedExpiryDate: any;
  isSalesSupportDistributor: number = 0;
  constructor(private router: Router, private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    if (!history.state.data) {
      this.router.navigate(['/']);
    } else {
      if (sessionStorage.getItem('BU') == 'ED') {
        this.labelData = this.cupReqData.labelData.filter(x => x.is_show == true);;
      }
      else {
        this.labelData = this.cupReqData.labelData;
      }
      this.changeFieldIndex();
      this.labelCount = this.labelData.length;
      this.halfWayIndex = Math.ceil(this.labelData.length / 2);
      this.orgRowData = this.cupReqData.formData;
      this.formData = JSON.parse(JSON.stringify(this.orgRowData));
      this.generateUserDetails();
      if (this.isDistributor && this.formData['status_id'] === 4) {
        this.getDistributorUserDetails();
      }
      this.callGetSalesSupportUsers();
    }
    this.getCupRequestDetails()
    this.isSalesSupportDistributor = Number(sessionStorage.getItem('SalesSupportDistributor'));
  }

  callGetSalesSupportUsers() {
    if (this.businessId === 3) {
      this.formData['request_id'] = this.formData['pm_request_id'];
      if (this.formData['status_id'] === 1) {
        this.getSalesSupportUsers();
      }
    }
    if (this.businessId == 2) {
      this.getSalesSupportUsers();
    }
  }

  changeFieldIndex() {
    if (sessionStorage.getItem('BU') === 'EM' || sessionStorage.getItem('BU') === 'ED') {
      if (this.cupReqData.formData['cups_intostock_id'] !== 5) {
        this.labelData = this.labelData.filter(l => l.field !== 'credit_back_amount');
        this.labelData = this.labelData.sort((a, b) => {
          return a.serial_no - b.serial_no;
        })
      } else {
        let labels = this.labelData;
        let index = labels.findIndex(function (item) {
          return item.field === 'distributor_name';
        });
        let soldToIndex = labels.findIndex(function (item) {
          return item.field === 'distributor_sold_to_name';
        });
        let endUserIndex = labels.findIndex(function (item) {
          return item.field === 'customer_name';
        });
        let shipToIndex = labels.findIndex(function (item) {
          return item.field === 'ship_to_name';
        });
        let disStateIndex = labels.findIndex(function (item) {
          return item.field === 'distributor_state';
        });
        labels.splice([index + 1], 0, labels[soldToIndex]);
        labels.splice([endUserIndex + 2], 0, labels[shipToIndex + 1]);
        labels.splice(soldToIndex + 2, 1);
        labels.splice(shipToIndex + 2, 1);
        labels.splice([index + 3], 0, labels[disStateIndex]);
        labels.splice(disStateIndex + 1, 1);
      }
    }
  }

  receivePriceData(priceData) {
    console.log(priceData);
  }


  generateUserDetails() {
    this.userAdmin = (sessionStorage.getItem('IsAdmin') === "1" || sessionStorage.getItem('IsAdmin') === "true") ? true : false;
    this.isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    switch (sessionStorage.getItem('BU')) {
      case 'EM': this.userId = sessionStorage.getItem('userid'); this.businessId = 1; this.halfWayIndex = this.formData['cups_intostock'] == 'CUP' ? this.halfWayIndex - 1 : this.halfWayIndex + 2; this.getSalesSupportUsers(); break;
      case 'EV': this.userId = sessionStorage.getItem('userid'); this.businessId = 2; break;
      case 'PM': this.userId = sessionStorage.getItem('userid'); this.businessId = 3; break;
      case 'ED': this.userId = sessionStorage.getItem('userid'); this.businessId = 4; this.halfWayIndex = this.formData['cups_intostock'] == 'CUP' ? this.halfWayIndex + 1 : this.halfWayIndex + 3; this.getSalesSupportUsers(); break;
      default: break;
    }
    this.hideCommentsForDistributor();
    this.leftColumns = this.labelData.slice(0, this.halfWayIndex)
    this.rightColumns = this.labelData.slice(this.halfWayIndex);
  }

  hideCommentsForDistributor() {
    if (sessionStorage.getItem('BU') === 'EM' || sessionStorage.getItem('BU') === 'ED') {
      this.labelData.map(item => {
        if (this.isDistributor && item.field == 'comments') {
          item.is_show = false;
        }
      });
    }
  }

  getSalesSupportUsers() {
    this.spinner.show();
    this._service.getSalesSupportUsers(this.businessId).subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.apiResp = response['response'];
        this.salesSupportUsers = this.apiResp;
        let fSupportUser = this.salesSupportUsers.find(ss => ss.user_id === Number(this.userId));
        if (fSupportUser) {
          this.isSalesSupportLogin = true;
        }
      } else {
        this._service.displayToastr(response);
      }
    })
  }

  getCupRequestDetails() {
    this.spinner.show();
    this._service.getCUPRequestDetails(this.formData['request_id'], this.businessId).subscribe(response => {
      this.spinner.hide();
      this.approvalHistoryAPI = response;
      this.productCupHistoryCols = this.approvalHistoryAPI.headerColumns.filter(h => h.form_name === 'ProductHistory' && h.is_grid_display);
      this.productCupHistoryData = this.approvalHistoryAPI.productHistory.filter(p => p.request_reference_number !== this.formData['request_reference_number']);
      this.endCustomerProductHistoryCols = this.approvalHistoryAPI.headerColumns.filter(h => h.form_name === 'ProductCustomerHistory' && h.is_grid_display);
      this.endCustomerProductHistoryData = this.approvalHistoryAPI.productCustomerHistory.filter(p => p.request_reference_number !== this.formData['request_reference_number']);
      this.marketSalesCols = this.approvalHistoryAPI.headerColumns.filter(h => h.form_name === 'EndMarketSalesReport' && h.is_grid_display);
      this.marketSalesData = this.approvalHistoryAPI.endMarketSaleReport;
      this.approvalHistoryData = this.approvalHistoryAPI.workFlowApprovalHistory;
      if (this.businessId === 3) {
        this.formData['status_id'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['status_id'];
        this.formData['status_name'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['status_name'];
        this.formData['suggested_selling_price'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['suggested_selling_price'];
        this.formData['supported_buy_price'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['supported_buy_price'];
        this.formData['rebate'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['rebate'];
        this.formData['effective_date'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['effective_date'];
        this.formData['expiration_date'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['expiration_date'];
        this.formData['pending_approver'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['pending_approver'];
        this.formData['internal_comments'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['internal_comments'];
        this.formData['external_comments'] = this.approvalHistoryAPI.pmRequestSubmissionData[0]['external_comments'];
      } else {
        this.formData['status_id'] = this.approvalHistoryAPI.requestSubmissionData[0]['status_id'];
        this.formData['expiration_date'] = this.approvalHistoryAPI.requestSubmissionData[0]['expiration_date'];
        this.formData['status_name'] = this.approvalHistoryAPI.requestSubmissionData[0]['status_name'];
        this.formData['discount_percent'] = this.approvalHistoryAPI.requestSubmissionData[0]['discount_percent'];
        this.formData['support_price_to_end_customer'] = this.approvalHistoryAPI.requestSubmissionData[0]['support_price_to_end_customer'];
        this.formData['frieght_allowance'] = this.approvalHistoryAPI.requestSubmissionData[0]['frieght_allowance'];
        this.formData['net_support_price'] = this.approvalHistoryAPI.requestSubmissionData[0]['net_support_price'];
        this.formData['other_allowance'] = this.approvalHistoryAPI.requestSubmissionData[0]['other_allowance'];
        this.formData['credit_back_amount'] = this.approvalHistoryAPI.requestSubmissionData[0]['credit_back_amount'];
        this.formData['pending_approver'] = this.approvalHistoryAPI.requestSubmissionData[0]['pending_approver'];
        this.formData['comments'] = this.approvalHistoryAPI.requestSubmissionData[0]['comments'];
        this.formData['external_comments'] = this.approvalHistoryAPI.requestSubmissionData[0]['external_comments'];
        this.formData['cups_auto_approval_id'] = this.approvalHistoryAPI.requestSubmissionData[0]['cups_auto_approval_id'];
        this.formData['created_by'] = this.approvalHistoryAPI.requestSubmissionData[0]['created_by'];
      }
      this.getWorkFlowUpdates();
    });
  }

  getDistributorUserDetails() {
    this.spinner.show();
    this._service.getDistributorUserDetails(this.formData['distributor_id']).subscribe(response => {
      if (!response['isError']) {
        this.duApiResp = response['response'];
        this.distributorUsers = this.duApiResp;
        let fUser = this.distributorUsers.find(du => du.distributor_user_id == this.userId);
        if (fUser) {
          this.dispDistributorRespBtns = true;
        }
      } else {
        this._service.displayToastr(response);
      }
    })
  }

  getWorkFlowUpdates() {
    if (!this.isDistributor) {
      this.spinner.show();
      this._service.getWorkFlowUpdateDetails(this.userId, this.formData['request_id'], this.businessId, this.isDistributor).subscribe(response => {
        this.apiResp = response;
        console.log(this.apiResp, "get workflow updates")
        if (this.apiResp['authorized'] && !this.apiResp['isError'] && this.apiResp['response'].length > 0) {
          this.workFlowResp = this.apiResp.response[0];
          if (this.workFlowResp.work_flow_approver_id == this.userId || this.workFlowResp.deligated_approver_id == this.userId) {
            this.displayBtns = true;
          }
        } else if (this.apiResp['authorized'] && this.apiResp['isError']) {
          this._service.displayToastr(response);
        } else if (!this.apiResp['authorized'] && this.apiResp['isError']) {
          this._service.displayToastr(response);
        } else {
          console.log("No workflow updates found");
        }
        this.spinner.hide();
      })
    }
  }

  goBack() {
    this.router.navigate(['/home']);
  }

  onSelectRow(rowObject) {
    let { rowData } = rowObject;
    this.spinner.show();
    this._service.getCUPRequestDetails(rowData['request_id'], this.businessId).subscribe(response => {
      this.spinner.hide();
      this.apiResp = response;
      if (this.businessId === 3) {
        this.sendCupDetails.emit(this.apiResp.pmRequestSubmissionData[0]);
      }
      else {
        this.sendCupDetails.emit(this.apiResp.requestSubmissionData[0]);
      }
    });
  }

  calculateEDPrice() {
    const dp = this.formData['distributor_price_from_basf'] ? Number(this.formData['distributor_price_from_basf']) : 0;
    const sp = this.reqForm['support_price_to_end_customer'] ? Number(this.reqForm['support_price_to_end_customer']) : 0;
    this.reqForm['net_support_price'] = 0;
    if (dp && sp) {
      this.reqForm['discount_percent'] = (((dp - sp) / dp) * 100).toFixed(2);
      this.reqForm['credit_back_amount'] = (dp - sp).toFixed(2);
    } else if (dp <= 0 && this.formData['cups_intostock'] === '3rd Party' || this.formData['cups_intostock'] === 'IntoStock') {
      this.reqForm['discount_percent'] = 0;
      this.reqForm['credit_back_amount'] = 0;
    }
  }

  calculatePrices() {
    if (this.businessId == 4) {
      this.calculateEDPrice();
    } else {
      const sp = Number(this.reqForm['support_price_to_end_customer']);
      const discount = Number(this.reqForm['discount_percent']);
      const fa = this.reqForm['freight_allowance'] ? Number(this.reqForm['freight_allowance']) : 0;
      const oa = this.reqForm['other_allowances'] ? Number(this.reqForm['other_allowances']) : 0;
      const dp = Number(this.formData['distributor_price_from_basf']);
      if ((this.businessId === 1 || this.businessId === 4) && sp && discount && fa >= 0 && oa >= 0 && dp >= 0) {
        this.reqForm['net_support_price'] = (sp - (sp * (discount) / 100) - fa - oa).toFixed(2).toString();
        this.reqForm['credit_back_amount'] = (dp - Number(this.reqForm['net_support_price'])).toFixed(2).toString();
      }
      const nsp = Number(this.formData['net_support_price']);
      if (this.businessId === 2 && nsp && dp && (this.formData['cups_intostock'] === '3rd Party' || this.formData['cups_intostock'] === 'IntoStock')) {
        this.reqForm['credit_back_amount'] = 0;
      } else if (this.businessId === 2 && nsp && dp && this.formData['cups_intostock'] === 'CUP') {
        this.reqForm['credit_back_amount'] = (dp - Number(this.reqForm['net_support_price'])).toFixed(2).toString();
      }
    }
  }

  doCalculations() {
    const SP = Number(this.reqForm['suggested_selling_price']);
    const SM = Number(this.formData['selling_margin']);
    if (SP && SM && this.formData['sell_price_UOM']) {
      this.reqForm['supported_buy_price'] = (SP - (SP * SM / 100)).toFixed(2).toString();
      if (this.formData['sell_price_UOM'] === 'LB') {
        this.reqForm['rebate'] = (Number(this.formData['list_price_lb']) - Number(this.reqForm['supported_buy_price'])).toFixed(2).toString();
      } else {
        this.reqForm['rebate'] = (Number(this.formData['list_price_kg']) - Number(this.reqForm['supported_buy_price'])).toFixed(2).toString();
      }
    }
  }

  getFormattedData(date) {
    return moment(date).format("yyyy-MM-DD");
  }

  populateExpiryDate() {
    this.activateForm['expirationDate'] = moment(this.activateForm['effectiveDate']).add(365, 'days').format('L');
  }

  sendCupRequest(funcType) {
    this.statusOfCup = funcType;
    switch (this.statusOfCup) {
      case 'a': this.headerName = 'Approve Confirmation'; this.workFlowResp['work_flow_status_id'] = 1; break;
      case 'r': this.headerName = 'Reject Confirmation'; this.workFlowResp['work_flow_status_id'] = 3; break;
      case 'rc': this.headerName = 'Reject with Counter Offer'; this.workFlowResp['work_flow_status_id'] = 4; break;
      default: this.headerName = ''; break;
    }
    this.dispEVEMDailog = true;
  }

  submitRequest() {
    switch (this.statusOfCup) {
      case 'a': this.generateRequest(); break;
      case 'r': this.generateRequest(); break;
      case 'rc': this.generateRCRequest(); break;
      default: break;
    }
  }

  generateRequest() {
    this.workFlowResp.cup_request_no = this.formData['request_reference_number'];
    this.workFlowResp.approvar_comments = this.reqForm['comments'] ? this.reqForm['comments'] : '';
    this.workFlowResp['businesunit_id'] = this.businessId;
    this.workFlowResp['request_id'] = this.formData['request_id'];
    this.workFlowResp['modified_by'] = Number(sessionStorage.getItem('userid'));
    this.updateWorkFlowStatus();
  }

  generateRCRequest() {
    this.workFlowResp['request_id'] = this.formData['request_id'];
    this.workFlowResp['support_price_to_end_user'] = (this.businessId === 1 || this.businessId === 2 || this.businessId === 4) ? Number(this.reqForm['support_price_to_end_customer']) : 0;
    if (this.businessId === 1 || this.businessId === 4) {
      this.generateEM_RCValues();
    }
    this.workFlowResp['net_support_price'] = (this.businessId === 1 || this.businessId === 2 || this.businessId === 4) ? Number(this.reqForm['net_support_price']) : 0;
    this.workFlowResp['credit_back_amount'] = (this.businessId === 1 || this.businessId === 2 || this.businessId === 4) ? Number(this.reqForm['credit_back_amount']) : 0;
    this.workFlowResp['suggested_selling_price'] = (this.businessId === 3) ? Number(this.reqForm['suggested_selling_price']) : 0;
    this.workFlowResp['selling_margin'] = (this.businessId === 3) ? Number(this.formData['selling_margin']) : 0;
    this.workFlowResp['supported_buy_price'] = (this.businessId === 3) ? Number(this.reqForm['supported_buy_price']) : 0;
    this.workFlowResp['rebate'] = (this.businessId === 3) ? Number(this.reqForm['rebate']) : 0;
    this.workFlowResp['created_by'] = Number(sessionStorage.getItem('userid'));
    this.workFlowResp['cup_request_no'] = this.formData['request_reference_number'];
    this.workFlowResp['businesunit_id'] = this.businessId;
    this.workFlowResp['approvar_comments'] = this.reqForm['comments'] ? this.reqForm['comments'] : '';
    this.workFlowResp['modified_by'] = Number(sessionStorage.getItem('userid'));
    this.updateWorkFlowRCStatus();

  }

  generateEM_RCValues() {
    this.workFlowResp['discount_percentage'] = Number(this.reqForm['discount_percent']);
    this.workFlowResp['frieght_allowance'] = this.reqForm['freight_allowance'] ? Number(this.reqForm['freight_allowance']) : this.formData['freight_allowance'];
    this.workFlowResp['other_allowance'] = this.reqForm['other_allowances'] ? Number(this.reqForm['other_allowances']) : this.formData['other_allowances'];
  }

  updateWorkFlowStatus() {
    this.spinner.show();
    this._service.updateWorkFLowStatus(this.workFlowResp).subscribe((response) => {
      this.apiResp = response;
      this.handleWorkFlowStatusBtns();
      this.spinner.hide();
    })
  }

  updateWorkFlowRCStatus() {
    this.spinner.show();
    this._service.updateWorkFLowRCStatus(this.workFlowResp).subscribe((response) => {
      this.apiResp = response;
      this.spinner.hide();
      this.handleWorkFlowStatusBtns();
    })
  }

  handleWorkFlowStatusBtns() {
    this.displayBtns = false;
    this.getWorkFlowUpdates();
    this.dispEVEMDailog = false;
    this.toastr.success("Request Submitted");
    this.getCupRequestDetails();
  }

  getCounterOfferDetails() {
    this.spinner.show();
    this._service.getRejectWithCounterOfferDetails(this.formData['request_id'], this.businessId, this.userId, this.isDistributor).subscribe(response => {
      this.rwcApiResp = response;
      this.rejectWithCounterOfferData = this.rwcApiResp.rejectWithCounterOffer[0];
      this.spinner.hide();
      this.dispCounterOffer = true;
    })
  }

  openActivatePopup() {
    this.dispActivateDialog = true;
  }

  activateCup() {
    let requestApi = {
      "request_reference_number": this.formData['request_reference_number'],
      "effective_date": this.activateForm['effectiveDate'],
      "expiration_date": this.activateForm['expirationDate'],
      "modified_by": "45"
    }
    this.spinner.show();
    this._service.submitActivateCup(requestApi).subscribe(_response => {
      this.dispActivateDialog = false;
      this.getCupRequestDetails();
      this.spinner.hide();
    })
  }

  cancelActivate() {
    this.dispActivateDialog = false;
  }

  sendCounterOfferResponse(responseValue: any) {
    this.spinner.show();
    let requestData = {
      "RequestId": this.formData['request_id'],
      "RequestReferenceNumber": this.formData['request_reference_number'],
      "BusinessUnitId": this.businessId,
      "DistributorResponse": Number(responseValue),
      "LoggedUserId": this.userId
    };
    this._service.updateDistributorResponse(requestData).subscribe(_response1 => {
      this.dispCounterOffer = false;
      this.toastr.success("Sent Distributor Response", "Success!");
      this.getCupRequestDetails();
      this.spinner.hide();
    })
  }

  openDelegationPopup() {
    this.currentDate = new Date();
    this.spinner.show();
    this._service.getApprovers(this.businessId).subscribe(response => {
      if (!response['isError']) {
        this.approvers = response['response'];
        this.toApprovers = this.approvers;
        this.fromApprovers = this.approvers;
        this.toApprovers = this.approvers.filter(a => a.userid != this.userId);
        this.spinner.hide();
        this.displayDelgationPopup = true;
      } else {
        this._service.displayToastr(response);
      }
    });
  }

  cancelDelegationPopup() {
    this.displayDelgationPopup = false;
  }

  submitDelegation() {
    this.spinner.show();
    let apiResponse: any;
    this.delegationRequest['cupNumber'] = this.formData['request_reference_number'];
    this.delegationRequest['toApproverUserId'] = this.selectedToApprover['userid'];
    this.delegationRequest['currentUserId'] = Number(this.userId);
    this.delegationRequest['toDate'] = moment(this.delegationRequest['toDate']).format("MM/DD/yyyy");
    this.delegationRequest['fromDate'] = moment(this.delegationRequest['fromDate']).format("MM/DD/yyyy");
    this._service.submitEMCupDelegationRequest(this.delegationRequest).subscribe(response => {
      if (!response['isError']) {
        this.spinner.hide();
        this.displayDelgationPopup = false;
        this._service.displayToastr(response);
      } else {
        this._service.displayToastr(response);
      }
    });
  }

  showCommentsDialog(commentsType) {
    this.editComments = false;
    this.displayComments = true;
    this.commentsList = [];
    this.internalCommentsList = [];
    this.externalCommentsList = [];
    this.newComments = '';
    if (this.formData['comments']) {
      this.commentsList = this.formData['comments'].split("||");
      if (this.commentsList[this.commentsList.length - 1] == '') {
        this.commentsList.pop();
      }
      this.internalCommentsList = this.formData['comments'].split("||");
      if (this.internalCommentsList[this.internalCommentsList.length - 1] == '') {
        this.internalCommentsList.pop();
      }
    }
    if (this.formData['internal_comments']) {
      this.internalCommentsList = this.formData['internal_comments'].split("||");
      if (this.internalCommentsList[this.internalCommentsList.length - 1] == '') {
        this.internalCommentsList.pop();
      }
    }
    if (this.formData['external_comments']) {
      this.externalCommentsList = this.formData['external_comments'].split("||");
      if (this.externalCommentsList[this.externalCommentsList.length - 1] == '') {
        this.externalCommentsList.pop();
      }
    }
    switch (commentsType) {
      case 'internal': this.internalComments = true; this.headerComments = 'Internal Comments'; break;
      case 'external': this.internalComments = false; this.headerComments = 'External Comments'; break;
      default: break;
    }
  }

  showComments() {
    this.editComments = true;
    this.newComments = '';
  }

  cancel() {
    this.editComments = false;
    this.newComments = '';
  }

  close() {
    this.displayComments = false;
  }

  submitComments(commentsType) {
    this.spinner.show();
    let currentDate = moment().format('MM/DD/yyyy');
    let commentsData = '';
    this.newComments = `${currentDate}: ${this.newComments} - by ${sessionStorage.getItem('loginuser')} ||`;
    if (commentsType === 'internal' && (this.businessId === 1 || this.businessId === 2 || this.businessId === 4)) {
      const isEndWithSlash = this.formData['comments'].endsWith('||');
      const newC = isEndWithSlash ? this.newComments : ` || ${this.newComments}`
      commentsData = this.formData['comments'] != '' ? `${this.formData['comments']} ${newC}` : `${this.newComments}`;
    }
    if (commentsType === 'internal' && (this.businessId === 3)) {
      const isEndWithSlash = this.formData['internal_comments'].endsWith('||');
      const newC = isEndWithSlash ? this.newComments : ` || ${this.newComments}`
      commentsData = this.formData['internal_comments'] != '' ? `${this.formData['internal_comments']} ${newC}` : `${this.newComments}`;
    }
    if (commentsType === 'external') {
      const isEndWithSlash = this.formData['external_comments'].endsWith('||');
      const newC = isEndWithSlash ? this.newComments : ` || ${this.newComments}`
      commentsData = this.formData['external_comments'] != '' ? `${this.formData['external_comments']} ${newC}` : `${this.newComments}`;
    }
    let reqData = {
      "request_id": this.formData['request_id'],
      "businessunit_id": this.businessId,
      "internal_comments": commentsData,
      "type_of_comment": commentsType,
      "updated_by": Number(this.userId)
    }
    this.spinner.show();
    this._service.updateComments(reqData).subscribe(response => {
      this.spinner.hide();
      this.close();
      this._service.displayToastr(response);
      this.getCupRequestDetails();
    })
  }

  updateExpiryDate() {
    this.dispEMExpiryUpdateDialog = true;
  }

  cancelUpdateExpiry() {
    this.dispEMExpiryUpdateDialog = false;
  }

  submitExpiryDate() {
    this.spinner.show();
    this._service.updateExpriyDate(this.formData['request_reference_number'], this.updatedExpiryDate, this.userId, this.businessId).subscribe(response => {
      this.spinner.hide();
      this.cancelUpdateExpiry();
      this._service.displayToastr(response);
      this.getCupRequestDetails();
    })
  }

  verifyCupRequest() {   
    this.router.navigate(['cups-request'], { state: { data: { cols: this.labelData, rowData: this.formData, type: 'edit', gridData: this.formData } } });
  }
}
