import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CupsService {

  businessUnit = new BehaviorSubject(sessionStorage.getItem('BU'));
  isAdmin = new BehaviorSubject(sessionStorage.getItem('IsAdmin'));
  isDistributor = new BehaviorSubject(sessionStorage.getItem('isDistributor'));
  isSalesSupport = new BehaviorSubject(sessionStorage.getItem('isSalesSupport'));
  isSalesrep = new BehaviorSubject(sessionStorage.getItem('isSalesRep'));
  userId = new BehaviorSubject(sessionStorage.getItem('userid'));
  sessionObject = new BehaviorSubject({});
  sortColumn = new BehaviorSubject('request_id');
  sortOrder = new BehaviorSubject(-1);

  constructor(private _http: HttpClient, private toastr: ToastrService) { }

  sendSortColumn(column: string) {
    this.sortColumn.next(column);
  }

  getSortColumn(): Observable<any> {
    return this.sortColumn.asObservable();
  }

  sendSortOrder(order: number) {
    this.sortOrder.next(order);
  }

  getSortOrder(): Observable<any> {
    return this.sortOrder.asObservable();
  }

  getSalesSupport(): Observable<any> {
    return this.isSalesSupport.asObservable();
  }

  getUserValue(): Observable<any> {
    return this.userId.asObservable();
  }

  sendUserValue(value) {
    this.userId.next(value);
  }

  sendSalesSupport(value) {
    this.isSalesSupport.next(value);
  }

  getSalesRep(): Observable<any> {
    return this.isSalesrep.asObservable();
  }

  sendSalesRep(value) {
    this.isSalesrep.next(value);
  }

  sendBusinessUnit(bu: string) {
    this.businessUnit.next(bu);
  }

  getBusinessUnit(): Observable<any> {
    return this.businessUnit.asObservable();
  }

  sendAdminValue(value) {
    this.isAdmin.next(value);
  }

  getAdminValue(): Observable<any> {
    return this.isAdmin.asObservable();
  }

  sendDistributorValue(value) {
    this.isDistributor.next(value);
  }

  getSessionObject() {
    return this.sessionObject.asObservable();
  }

  sendSessionObject(value) {
    this.sessionObject.next(value);
  }

  getDistributorValue(): Observable<any> {
    return this.isDistributor.asObservable();
  }

  sendEmailReminder(requestId, businessUnitId, requestRef: string, expiryDate: string, requestStatus) {
    return this._http.get(`${environment.sendEmailReminder}/${requestId}/${businessUnitId}/${requestRef}/${expiryDate}/${requestStatus}`).pipe(map((response) => response));
  }

  resendSupportConfirmationEmail(requestId, businessUnitId, requestRef, requestStatus) {
    return this._http.get(`${environment.resendSupportConfirmationEmail}/${requestId}/${businessUnitId}/${requestRef}/${requestStatus}`).pipe(map((response) => response));
  }

  deleteSupport(requestId, businessUnitId) {
    return this._http.get(`${environment.deleteSupport}/${requestId}/${businessUnitId}`).pipe(map((response) => response));
  }

  //CUPS Request Services
  getMasterData(distribuorSoldToId: any, soldToId: any, formName: any, distributorId: any, isInternalUser: any) {
    let isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    return this._http.get(`${environment.getMasterData}/${distribuorSoldToId}/${soldToId}/${formName}/${distributorId}/${!isDistributor}`).pipe(map((response) => response));
  }

  getGridData(requestStatus, formName, userId, isDistributor, SalesSupportDistributor) {
    return this._http.get(`${environment.getGridData}/${requestStatus}/${formName}/${userId}/${isDistributor}/${SalesSupportDistributor}`).pipe(map((response) => response));
  }

  getAllGridData(formName, userId, isDistributor) {
    let salesSupportDistributor = parseInt(sessionStorage.getItem('SalesSupportDistributor'), 0);
    return this._http.get(`${environment.getGridData}/0/${formName}/${userId}/${isDistributor}/${salesSupportDistributor}`).pipe(map((response) => response));
  }

  updateUserGridCols(payload: any, userId: any, isdistributor: any) {
    return this._http.post(`${environment.updateUserGridCols}/${userId}/${isdistributor}`, payload).pipe(map((response) => response));
  }
  getProductDetails(productId) {
    return this._http.get(`${environment.getProductDetails}/${productId}`).pipe(map((response) => response));
  }

  submitRequest(requestData) {
    return this._http.post(`${environment.submitEVEMRequest}`, requestData).pipe(map((response) => response));
  }

  submitPMRequest(requestData) {
    return this._http.post(`${environment.submitPMRequest}`, requestData).pipe(map((response) => response));
  }

  getApprovalHistory(distrbuitorId, formName) {
    return this._http.get(`${environment.getApprovalHistory}/${distrbuitorId}/${formName}`).pipe(map((response) => response));
  }

  getCUPRequestDetails(requestId: any, businessUnitID: any) {
    let salesSupportDistributor = parseInt(sessionStorage.getItem('SalesSupportDistributor'), 0);
    return this._http.get(`${environment.getRequestDetails}/${requestId}/${businessUnitID}/${sessionStorage.getItem('isDistributor')}/${salesSupportDistributor}`).pipe(map((response) => response));
  }

  getSalesSupportDistributors(userId: any, isSalesSupport: any, isSalesRep: any, businessUnit: any) {
    return this._http.get(`${environment.getSalesSupportDistributors}/${userId}/${isSalesSupport}/${isSalesRep}/${businessUnit}`).pipe(map((response) => response));
  }

  getSoldTo(userId: any, businessUnit: any, isInternalUser: any) {
    let isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    let salesSupportDistributor = parseInt(sessionStorage.getItem('SalesSupportDistributor'), 0);
    return this._http.get(`${environment.getSoldToList}/${userId}/${businessUnit}/${!isDistributor}/${salesSupportDistributor}`).pipe(map((response) => response));
  }

  submitActivateCup(requestData) {
    return this._http.post(`${environment.activateCup}`, requestData).pipe(map((response) => response));
  }

  getApprovers(businessUnit: any) {
    return this._http.get(`${environment.getDelegationApprovers}/?businessUnit=${businessUnit}`).pipe(map((response) => response));
  }

  submitDelegationRequest(requestData) {
    return this._http.post(`${environment.createDelegation}`, requestData).pipe(map((response) => response));
  }

  submitEMCupDelegationRequest(requestData) {
    return this._http.post(`${environment.createEmCupDelegation}`, requestData).pipe(map((response) => response));
  }

  submitMassChange(requestData, businessUnit: any) {
    return this._http.post(`${environment.massChange}?BU=${businessUnit}`, requestData).pipe(map((response) => response));
  }

  updateExpriyDate(request_reference_number, expiryDate, userId, businessUnit) {
    return this._http.get(`${environment.updateExpiryDate}/${request_reference_number}/${expiryDate}/${userId}/${businessUnit}`).pipe(map((response) => response));
  }

  //Distributor's Services
  getDistributorDetails(distributorId: any) {
    return this._http.get(`${environment.getDistributorDetails}/${distributorId}`).pipe(map((response) => response));
  }

  getDistributorUserDetails(distributorId: any) {
    return this._http.get(`${environment.getDistributorUserDetails}/${distributorId}`).pipe(map((response) => response))
  }

  getDistributorDropdownData(businessUnitId: any) {
    return this._http.get(`${environment.getDistributorDropDownData}/${businessUnitId}`).pipe(map((response) => response));
  }

  submitDistributorData(requestData: any) {
    return this._http.post(`${environment.submitDistributorDetails}`, requestData).pipe(map((response) => response))
  }

  saveDistributorUsertoThreeIamCall(requestData: any) {
    return this._http.post(`${environment.saveDistributorUsertoThreeIamCall}`, requestData).pipe(map((response) => response))
  }
  getDistributorsGridData(businessUnit: any, userId: any, isdistributor: any) {
    return this._http.get(`${environment.getDistributorGridData}/${businessUnit}/${userId}/${isdistributor}`).pipe(map((response) => response));
  }

  createCustomer(customerData: any) {
    return this._http.post(`${environment.createCustomer}`, customerData).pipe(map((response) => response));
  }

  createCompetitor(competitorData: any) {
    return this._http.post(`${environment.createCompetitor}`, competitorData).pipe(map((response) => response));
  }

  //User's Services
  submitUser(requestData: any) {
    return this._http.post(`${environment.createUser}`, requestData).pipe(map((response) => response))
  }

  getUsers() {
    return this._http.get(`${environment.getUsers}`).pipe(map((response) => response));
  }

  getAllUsers() {
    return this._http.get(`${environment.getAllUsers}`).pipe(map((response) => response));
  }
  getSAMLService(): Observable<any> {
    return this._http.get(`${environment.getSamlURL}`).pipe(map((response) => response));
  }

  getSalesSupportUsers(businessUnit: any) {
    return this._http.get(`${environment.getSalesSupportUsers}/${businessUnit}`).pipe(map((response) => response));
  }

  getBUUsers(businessUnit: any) {
    return this._http.get(`${environment.getBUUsers}/${businessUnit}`).pipe(map((response) => response));
  }

  getBUInternalUsers(businessUnit: any) {
    return this._http.get(`${environment.getBUInternalUsers}/${businessUnit}`).pipe(map((response) => response));
  }

  getUserDetailsById(emailId: any) {
    return this._http.get(`${environment.getUsersByEmailId}/${emailId}`).pipe(map((response) => response));
  }

  getProductDetailsById(productId: any, effectiveDate: any) {
    return this._http.get(`${environment.getProductDetailsById}/${productId}/${effectiveDate}`).pipe(map((response) => response));
  }

  deleteUser(requestData: any) {
    return this._http.post(`${environment.deleteUser}`, requestData).pipe(map((response) => response))
  }

  // Approval Configuration Service
  getAdminConfigMaster() {
    return this._http.get(`${environment.getAdminConfigMaster}`).pipe(map((response) => response));
  }
  saveAdminConfig(adminConfigData) {
    return this._http.post(`${environment.saveAdminConfig}`, adminConfigData).pipe(map((response) => response))
  }
  getApprovalConfig(RegionId, CountryId, BusinessUnitId, SBUId, TypeId) {
    return this._http.get(`${environment.getApprovalConfig}/${RegionId}/${CountryId}/${BusinessUnitId}/${SBUId}/${TypeId}`).pipe(map((response) => response));
  }

  //Rebates Services 
  getRebateFormData(requestId: any, businessUnitID: any) {
    return this._http.get(`${environment.getRebateFormData}/${requestId}/${businessUnitID}`).pipe(map((response) => response));
  }

  getRebateDetails(rebateRequestId: any, businessUnitID: any) {
    return this._http.get(`${environment.getRebateDetails}/${rebateRequestId}/${businessUnitID}`).pipe(map((response) => response));
  }

  createRebate(requestData: any) {
    return this._http.post(`${environment.createRebate}`, requestData).pipe(map((response) => response))
  }

  getRebateGridData(businessUnitID: any, rebateStatus: any, salesSupportDistributor: number) {
    return this._http.get(`${environment.getRebatedGridData}/${businessUnitID}/${sessionStorage.getItem('userid')}/${sessionStorage.getItem('isDistributor')}/${rebateStatus}/${salesSupportDistributor}`).pipe(map((response) => response));
  }

  uploadRebateTemplate(templateObject: any) {
    return this._http.post(`${environment.uploadRebateTemplate}`, templateObject).pipe(map((response) => response));
  }

  uploadRebateExport(templateObject: any) {
    return this._http.post(`${environment.uploadRebateExport}`, templateObject).pipe(map((response) => response));
  }

  downloadRebates(businessUnitId: any, distributorUserId: any) {
    return this._http.get(`${environment.downloadRebates}/${businessUnitId}/${distributorUserId}`, { responseType: 'blob' }).pipe(map((response) => response));
  }

  downloadRebatesEMEV(businessUnitID: any, userId: any, selectedRebates: any) {
    return this._http.get(`${environment.downloadRebatesEMEV}/${businessUnitID}/${userId}/${selectedRebates}`).pipe(map((response) => response));
  }

  //workFlow Services
  updateWorkFLowStatus(reqData: any) {
    return this._http.post(`${environment.updateWorkFlow}`, reqData).pipe(map((response) => response));
  }

  updateWorkFLowRCStatus(reqData: any) {
    return this._http.post(`${environment.updateRCWrokFlow}`, reqData).pipe(map((response) => response));
  }

  getWorkFlowUpdateDetails(userId: any, reqId: any, businessUnit: any, isDistributorUser: any) {
    return this._http.get(`${environment.getWorkFlowDetails}/${userId}/${reqId}/${businessUnit}/${isDistributorUser}`).pipe(map((response) => response));
  }

  getRejectWithCounterOfferDetails(cupRequestId: any, businessUnit: any, userId: any, isDistributorUser: any) {
    return this._http.get(`${environment.getCounterOfferInfo}/${cupRequestId}/${businessUnit}/${userId}/${isDistributorUser}`).pipe(map((response) => response));
  }

  updateDistributorResponse(reqData: any) {
    return this._http.post(`${environment.updateDistributorResponse}`, reqData).pipe(map((response) => response));
  }

  //Upload
  UploadExcelTemplate(templateObject: any) {
    return this._http.post(`${environment.uploadExcelTemplate}`, templateObject).pipe(map((response) => response));
  }

  UploadEMMassChangeExcelTemplate(templateObject: any) {
    return this._http.post(`${environment.MassChangeUploadExcel}`, templateObject).pipe(map((response) => response));
  }

  UploadEndMarketSaleReport(templateObject: any) {
    return this._http.post(`${environment.UploadEndMarketSaleReport}`, templateObject).pipe(map((response) => response));
  }

  getEndMarketSaleReport() {
    return this._http.get(`${environment.getEndMarketSaleReport}`).pipe(map((response) => response));
  }

  //admin 
  postSalesSupportUsers(data: any) {
    return this._http.post(`${environment.postSalesSupUsers}`, data).pipe(map((response) => response));
  }
  postSalescommercialUsers(data: any) {
    return this._http.post(`${environment.postSalescommercialUsers}`, data).pipe(map((response) => response));
  }

  getAllSaleSupportPricingAdmin(businessunit_id: any) {
    return this._http.get(`${environment.getAllSaleSupportPricingAdmin}?businessUnit=${businessunit_id}`).pipe(map((response) => response));
  }

  getAllSalesCommericalManger(businessunit_id: any) {
    return this._http.get(`${environment.getAllSalesCommericalManger}?businessUnit=${businessunit_id}`).pipe(map((response) => response));
  }

  getAllProductsyBu(id: any, countryId: any) {
    return this._http.get(`${environment.getAllProductsyBu}/${id}/${countryId}`).pipe(map((response) => response));
  }

  UpdateProductMangerMapping(data: any) {
    return this._http.post(`${environment.UpdateProductMangerMapping}`, data).pipe(map((response) => response));
  }

  DeleteProductMangerMapping(productid, modifiedBy) {
    return this._http.post(`${environment.DeleteProductMangerMapping}/${productid}/${modifiedBy}`, {}).pipe(map((response) => response));
  }

  updateEVProductDetails(data: any) {
    return this._http.post(`${environment.postEVProductDetails}`, data).pipe(map((response) => response));
  }

  ChangeProductStatus(productId, modifiedBy) {
    return this._http.post(`${environment.postChangeProductStatus}/${productId}/${modifiedBy}`, {}).pipe(map((response) => response));
  }

  ChangeProductManager(FromProductManagerID, ToProductManagerID) {
    const reqObj = {
      FromProductManagerID: FromProductManagerID,
      ToProductManagerID: ToProductManagerID
    }
    return this._http.post(environment.postChangeProductManager, reqObj).pipe(map((response) => response));
  }

  displayToastr(response) {
    if (response['authorized'] && !response.isError && response['responseMessage'] != '') {
      this.toastr.success(response['responseMessage'], 'Success!');
    }
    if (response.isError && response['errorMessage'] != '') {
      this.toastr.error(response['errorMessage'], 'Error!')
    }
  }

  updateComments(reqData: any) {
    return this._http.post(`${environment.updateComments}`, reqData).pipe(map((response) => response));
  }

  getProductPricingRecord(buid: any, articlNumber: any, soldTo: any, shipTo: any, freightTerms: any, effectiveDate: any, salesOrg: string, supportType: number) {
    return this._http.get(`${environment.getProductPricing}/${buid}/${articlNumber}/${soldTo}/${shipTo}/${freightTerms}/${effectiveDate}/${salesOrg}/${supportType}`).pipe(map((response) => response));
  }

  getEMPPMHistory(cupReqId, businessUnitId, isDistributor) {
    const userId = sessionStorage.getItem('userid');
    let salesSupportDistributor = parseInt(sessionStorage.getItem('SalesSupportDistributor'), 0);
    return this._http.get(`${environment.getEMPMHistory}?reqId=${cupReqId}&BU=${businessUnitId}&is_distributor=${isDistributor}&userId=${userId}&salesSupportDistributor=${salesSupportDistributor}`).pipe(map((response) => response));
  }

  getAccountData() {
    return this._http.get(`${environment.getAccountData}`).pipe(map(res => res));
  }
  getAccountDetails() {
    return this._http.get(`${environment.getAllAccountDetails}`).pipe(map(res => res));
  }
  insertAccountDetails(obj: any) {
    return this._http.post(`${environment.insertAccountDetails}`, obj).pipe(map(res => res));

  }
  deleteAcccountDetails(obj: any) {
    return this._http.post(`${environment.deleteAccountDetails}`, obj).pipe(map(res => res));
  }
  updateAccountDetails(obj: any) {
    return this._http.post(`${environment.updateAccountDetails}`, obj).pipe(map(res => res));
  }
}
