import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';


export class AccountManagers {
  public salesRepUserId: number;
  public salesRep: string;
  public salesRepEmail: string;
  public industry_id?: number;
  public region_id?: number;
}

@Component({
  selector: 'app-distributor-request',
  templateUrl: './distributor-request.component.html',
  styleUrls: ['./distributor-request.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class DistributorRequestComponent implements OnInit {
  @Input() recieveFormData: any;
  @Input() formType: any;
  @Input() businessUnit: any;
  @Output() reLoadGrid = new EventEmitter();
  selectedOrganisation: any;
  organisationDD: any;
  accountManagers: AccountManagers[] = [];
  formData = {};
  distributionChannelDD: any;
  distributionRegionDD: any;
  selectedDistribution: any;
  sbuDD: any;
  selectedSBUS: any;
  selectedDistributorRegions = [];
  shipTo: any;
  tempShipTo = [];
  selectedShip: any;
  productsDD: any;
  selectedProducts: any;
  industriesDD: any;
  selectedIndustries = [];
  masterAPIResp: any;
  usersData = [];
  orgUsersData = [];
  userHeaderCols: any;
  defaultWidth = '100%';
  businessUnitName = "";
  selectedSalesRep: any;
  salesRepDD: any;
  countryDD: any;
  selectedCountry: any;
  displayAddShipTo: boolean = false;
  shipToName = "";
  shipToId = "";
  shipToCity = "";
  shipToAddress = "";
  displayShipToView: boolean = true;
  accountManagersDD: any;
  salesManagersDD: any;
  tempAccountManagersDD = [];
  apiRespUsers: any;
  countryName = "";
  apiResp: any;
  productsData: any;
  shipToData: any;
  sbuData: any;
  usersDataResp: any;
  salesRepData: any;
  formMode = "";
  distributorData: any;
  dupAccountManagers = {
    manager: []
  }
  dist_contact_price_support_email: string[];
  dist_product_manager_email: string[];
  pricingAdminEmail: string[];
  adminAndPricingSupportMappings: any;
  requestApi = {
    "distributor_id": 0,
    "distributor_name": "",
    "distributor_mailing_address": "",
    "distributor_city": "",
    "distributor_state": "",
    "distributor_zipcode": "",
    "distributor_business_unit": 1,
    "distributor_region": 1,
    "distributor_country": 0,
    "is_active": true,
    "created_by": 0,
    "basf_payer": "",
    "pricing_Admin_First_Name": "",
    "pricing_Admin_Last_Name": "",
    "pricing_Admin_Email": "",
    "sales_org": 0,
    "distribution_channel": 0,
    "sbu": [],
    "account": [],
    "sold_to": {},
    //"industry": "",
    //"basf_sales_account_email": "",
    //"product_id": "",
    "products": [],
    //"basf_sale_account_user_id": "",
    "distributor_contact_for_price_support_first_name": "",
    "distributor_contact_for_price_support_last_name": "",
    "distributor_contact_for_price_support_email": "",
    //"distributor_businessunit_mapping_id": 0,
    "distributor_email": "abc@123.com",
    "salesReps": [],
    "userDetails": [],
    //"salesAccountEmails":[],
    "salesSupport": [],
    "adminAndPricingSupportMappings": [],
    "industries": []
  }

  salesRep = [
    {
      "distributor_sales_rep_mapping_id": 0,
      "distributor_id": 0,
      "user_id": 4,
      "industry_id": 161
    }
  ];

  salesAcct = [
    {
      "sales_rep_email_id": 0,
      "sales_rep_name": "salesRep1",
      "sales_rep_email": "salesRep1@fre.com"
    }
  ];
  accountManagerDUP = [];
  selectedSalesSupport: any[] = [];
  selectedSalesSupportbyBU: any[] = [];
  salesSupportUsers: any;
  displaySalesSupportUsers: any;
  pricingAdminEmailbyInd: any[] = [];
  dist_product_manager_emailbyInd: any[] = [];
  distributor_user_id = 0;
  customersDD: any;
  selectedCustomers: any = [];
  customerData: any;

  constructor(private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.spinner.show();
    switch (this.businessUnit) {
      case 1: this.businessUnitName = 'EM'; break;
      case 2: this.businessUnitName = 'EV'; break;
      case 3: this.businessUnitName = 'PM'; break;
      case 4: this.businessUnitName = 'ED'; break;
      default: this.businessUnitName = 'EM'; break;
    }
    this.userHeaderCols = [
      { field: 'first_name', header: 'First Name', is_show: true, width: this.defaultWidth, is_edit: true, serialNo: 2, placeholder: 'Enter your first_name' },
      { field: 'last_name', header: 'Last Name', is_show: true, width: this.defaultWidth, is_edit: false, serialNo: 3, placeholder: 'Enter your last_name' },
      { field: 'email', header: 'Email Address', is_show: true, width: this.defaultWidth, is_edit: true, serialNo: 5, placeholder: 'Enter your Email Address' }
    ]
    this._service.getDistributorDropdownData(this.businessUnit).subscribe(response => {
      if (!response['isError']) {
        this.masterAPIResp = response;
        this.formMode = "New";
        this.customersDD = this.getUniqueListBy(this.masterAPIResp.customerData, 'customer_name');
        this.getSalesOrganisations();
        this.getCountriesDropdwon();
        this.getDistributorChannels();
        this.getDistributorRegions();
        this.getSBUS();
        this.getProductsDropdown();
        this.getIndustriedDropdown();
        this.getSalesRep();
      } else {
        this.spinner.hide();
        this._service.displayToastr(response);
      }
    })
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  getEditFormData() {
    if (this.recieveFormData && this.formType === 'Edit') {
      this._service.getDistributorDetails(this.recieveFormData['distributor_id']).subscribe(response1 => {
        if (!response1['isError']) {
          this.spinner.hide();
          this.apiResp = response1;
          this.formMode = "Edit";
          this.distributorData = this.apiResp['distributorData'][0];
          this.productsData = this.apiResp['distributorProductsMappingData'];
          this.shipToData = this.apiResp['distributorShipToData'];
          this.sbuData = this.apiResp['distributorSbuData'];
          this.customerData = this.apiResp['customersData'];
          this.adminAndPricingSupportMappings = this.apiResp['adminAndPricingSupportMappings'];
          this.salesSupportUsers = this.apiResp['distributorSalesSupport'];
          this.usersData = this.apiResp['distributorUserDetailsData'].filter(u => u.email != this.formData['distributor_contact_for_price_support_email'] && u.email != this.formData['pricing_Admin_Email']);
          this.usersData.forEach((u, index) => {
            u.index = index;
          })
          this.orgUsersData = JSON.parse(JSON.stringify(this.usersData));
          this.salesRepData = this.apiResp['salesRepEmailData'];
          this.generateFormData();
        } else {
          this.spinner.hide();
          this._service.displayToastr(response1);
        }
      });
    }
  }

  getSalesOrganisations() {
    this.organisationDD = this.masterAPIResp.requestMaster.filter(rm => rm.master_name === 'SalesOrg');
  }

  getDistributorChannels() {
    this.distributionChannelDD = this.masterAPIResp.requestMaster.filter(rm => rm.master_name === 'DistributorChannel');
  }

  getDistributorRegions() {
    this.distributionRegionDD = this.masterAPIResp.requestMaster.filter(rm => rm.master_name === 'DistributorRegion');
  }

  getSBUS() {
    this.sbuDD = this.masterAPIResp.requestMaster.filter(rm => rm.master_name === 'SBU');
  }

  getShipToDropdown() {
    this.shipTo = this.masterAPIResp.requestMaster.filter(rm => rm.master_name === 'ShipTo');
  }

  getProductsDropdown() {
    this.productsDD = this.masterAPIResp.products;
  }

  getIndustriedDropdown() {
    this.industriesDD = this.masterAPIResp.requestMaster.filter(rm => rm.master_name === 'Industry');
  }

  getCountriesDropdwon() {
    this.countryDD = this.masterAPIResp.countryMaster;
  }

  getSalesRep() {
    this.spinner.show();
    this._service.getBUInternalUsers(this.businessUnit).subscribe(response => {
      if (!response['isError']) {
        this.apiRespUsers = response['lstUsers'];
        this.accountManagersDD = this.apiRespUsers.filter(u => !u.is_distributor_user && u.is_active);
        this.salesManagersDD = JSON.parse(JSON.stringify(this.accountManagersDD));
        this.getEditFormData();
        this.spinner.hide();
      } else {
        this._service.displayToastr(response);
      }
    });
  }

  onSelectSalesSupportUsers(sbu) {
    let currSalesSupport = [];
    this.selectedSalesSupport = this.selectedSalesSupport.filter(item => item.sbuName != sbu.value)
    this.selectedSalesSupportbyBU[sbu.request_master_id].forEach(item => {
      currSalesSupport.push({
        basf_user_id: item.basf_user_id,
        email: item.email,
        user_id: item.user_id,
        master_key: sbu.master_key,
        sbuName: sbu.value,
        request_master_id: sbu.request_master_id
      });
    });
    this.selectedSalesSupport.push(...currSalesSupport);
  }

  onChangeDDValue(event, value) {
    if (event.itemValue) {
      this.appendValuestoAccountManager(event.itemValue.request_master_id);
    } else {
      if (event.value) {
        event.value.forEach(v => {
          this.appendValuestoAccountManager(v.request_master_id);
        })
      }
    }
  }

  onSBUPanelHidden() {
    let temp: any[] = [];
    let missingitem = [];
    this.sbuDD.forEach(x => {
      let found = this.selectedSBUS.find(y => y.value == x.value);
      if (found === undefined) {
        missingitem.push(x)
      }
    });
    for (let item of missingitem) {
      this.selectedSalesSupportbyBU[item.value] = [];
    }
    for (let item of this.selectedSBUS) {
      let filtered = this.selectedSalesSupport.filter(x => x.sbuName == item.value)
      temp.push(...filtered);
    }
    this.selectedSalesSupport = JSON.parse(JSON.stringify(temp));
  }

  onChangeSBUValue(_event2) {
    let tempProducts = [];
    if (this.selectedProducts.length > 0) {
      let selectedProducts = JSON.parse(JSON.stringify(this.selectedProducts));
      let tempSelectedProducts = [];
      this.selectedSBUS.forEach(sbu => {
        let filteredProducts = [];
        let filteredSelectedProducts = [];
        filteredProducts = this.masterAPIResp.products.filter(pd => pd.sbu === sbu.request_master_id);
        tempProducts.push(...filteredProducts);
        filteredSelectedProducts = selectedProducts.filter(sp => sp.sbu === sbu.request_master_id);
        tempSelectedProducts.push(...filteredSelectedProducts);
      })
      this.productsDD = JSON.parse(JSON.stringify(tempProducts.filter(p => p.country_Id === this.selectedCountry.country_id)));
      this.selectedProducts = JSON.parse(JSON.stringify(tempSelectedProducts));
    }
  }

  onIndustryPanelHidden() {
    let missingItems = [];
    for (let item of this.industriesDD) {
      let found = this.selectedIndustries.find(x => x.value == item.value);
      if (found == undefined || found == null) {
        missingItems.push(found);
      }
    }
    for (let item of missingItems) {
      this.pricingAdminEmailbyInd[item.request_master_id] = [];
      this.dist_product_manager_emailbyInd[item.request_master_id] = [];
    }
  }

  appendValuestoAccountManager(masterId) {
    let salesRepDetailsFound = this.businessUnitName == 'ED' ? this.accountManagers.some(am => am.region_id === masterId) : this.accountManagers.some(am => am.industry_id === masterId);
    if (!salesRepDetailsFound) {
      let am = {
        salesRepUserId: 0,
        salesRep: "",
        salesRepEmail: "",
        region_id: this.businessUnitName == 'ED' ? masterId : 0,
        industry_id: this.businessUnitName != 'ED' ? masterId : 0
      };
      this.accountManagers.push(am);
    } else {
      this.accountManagers = this.businessUnitName == 'ED' ? this.accountManagers.filter(am => am.region_id !== masterId) : this.accountManagers.filter(am => am.industry_id !== masterId);
    }
    let fManagers = [];
    this.accountManagers.forEach(a => {
      let fAccountManager1 = this.accountManagersDD.find(am1 => am1.user_id === a.salesRepUserId);
      if (fAccountManager1) {
        fManagers.push(fAccountManager1);
      }
    });
    if (fManagers.length) {
      this.dupAccountManagers['manager'] = JSON.parse(JSON.stringify(fManagers));
    }
    if (fManagers.length !== this.accountManagers.length) {
      this.dupAccountManagers['manager'].length = this.accountManagers.length;
    }
  }

  onAccountMangerSelection(_event, value, index) {
    this.accountManagers[index].salesRepUserId = value.user_id;
    this.accountManagers[index].salesRep = value.basf_user_id;
    this.accountManagers[index].salesRepEmail = value.email;
  }
  addNewUser() {
    let userObj = {
      index: Math.max(...this.usersData.map(o => o.index), 1) + 1,
      // userName: '',
      first_name: '',
      last_name: '',
      email: '',
      edit: true,
      newUser: true
    };
    let newUserSpace = this.usersData.find(user => user.newUser);
    if (newUserSpace) {
      this.toastr.warning("Please save the current new user", "Warning!");
      return;
    }
    this.usersData.push(userObj);
  }

  removeUser(user) {
    this.usersData = this.usersData.filter(u => u.index === user.index);
  }

  cancelUserEdit(rowData) {
    if (this.orgUsersData.length > 0 && !rowData.newUser) {
      let fUser = this.orgUsersData.find(user => user.index === rowData.index);
      this.usersData.forEach(user => {
        if (user.index === fUser.index) {
          user.first_name = fUser.first_name;
          user.last_name = fUser.last_name;
          user.email = fUser.email;
          user.edit = false;
          user.newUser = fUser.newUser;
        }
      });
    } else {
      this.usersData = this.usersData.filter(user => user.index !== rowData.index);
    }
  }

  openAddShipTo() {
    this.displayAddShipTo = true;
  }

  closeAddShipTo() {
    this.displayAddShipTo = false;
  }

  addShipTo() {
    let shipToObj = {
      id: this.tempShipTo.length + 1,
      shipToName: this.shipToId,
      shipToId: this.shipToId,
      shipToCity: this.shipToCity,
      shipToFullName: `${this.shipToId}-${this.shipToName}-${this.shipToCity}`,
      shipToAddress: this.shipToAddress
    };
    let fTempShipTo = this.tempShipTo.find(ts => ts.shipToName == this.shipToName);
    if (!fTempShipTo) {
      this.tempShipTo.push(shipToObj);
      this.shipTo = JSON.parse(JSON.stringify(this.tempShipTo));
      this.shipToName = "";
      this.shipToId = '';
      this.shipToCity = '';
      this.shipToAddress = '';
    }
    this.displayAddShipTo = false;
  }

  removeShipTo() {
    this.displayShipToView = false;
  }

  filterShipTo() {
    this.displayShipToView = true;
    this.selectedShip.forEach(ss => {
      this.tempShipTo = this.tempShipTo.filter(st => st.id !== ss.id);
    });
    this.shipTo = JSON.parse(JSON.stringify(this.tempShipTo));
  }

  cancelRemoveShipTo() {
    this.displayShipToView = true;
  }

  saveUserData(rowData) {
    let fUser = this.orgUsersData.some(user => user.email === rowData.email);
    if ((fUser && rowData.newUser)) {
      this.toastr.error(`User with email: ${rowData.email} already exists`, 'Error!');
      return;
    }
    this.saveDistributorUsertoThreeIamCall(rowData);

  }

  getuserDetailsbyemailId(email) {
    this._service.getUserDetailsById(email).subscribe(response => {
      if (!response['isError']) {
        return 0;
      }
      else {
        this.apiResp = response['lstUsers'];
        return this.apiResp[0].user_id;
      }
    })
  }
  checkUserExists(email, rowData, refId) {
    this.spinner.show();
    this._service.getUserDetailsById(email).subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.apiResp = response['lstUsers'];
        if (this.apiResp.length && this.apiResp[0].businessunit_id == this.businessUnit) {
          this.distributor_user_id = this.apiResp[0].user_id;
        } else {
          this.distributor_user_id = 0;
        }

        this.usersData.forEach((user) => {
          if (user.index === rowData.index) {
            user.first_name = rowData.first_name;
            user.last_name = rowData.last_name;
            user.email = rowData.email;
            user.edit = false;
            user.newUser = false;
            user.user_id = this.distributor_user_id;
            user.created_by = Number(sessionStorage.getItem('userid'));
            user.refId = refId;
          }
        });
        this.orgUsersData = JSON.parse(JSON.stringify(this.usersData));
        console.log(this.orgUsersData);

      } else {
        this._service.displayToastr(response);
      }
    });
  }

  saveDistributor() {
    this.formData['pricingAdminEmail'] = (this.pricingAdminEmail != undefined && this.pricingAdminEmail.length > 0) ? this.pricingAdminEmail.join(",") : '';
    if (this.checkDistributorValidation()) {
      this.requestApi.distributor_name = this.formData['distributorName'];
      this.requestApi.distributor_city = this.formData['distributorCity'];
      this.requestApi.distributor_state = (this.businessUnitName === 'EM' || this.businessUnitName === 'ED') ? this.formData['distributorState'] : '';
      this.requestApi.sales_org = this.selectedOrganisation.request_master_id;
      this.requestApi.distributor_country = this.selectedCountry.country_id;
      this.requestApi.distribution_channel = this.selectedDistribution.request_master_id;
      this.requestApi.sbu = this.generateSBUObj();
      this.requestApi.account = this.generateCustomerbj();
      this.requestApi.sold_to = this.generateSoldToObj();
      this.requestApi.adminAndPricingSupportMappings = this.generateEmailsByindustryID_for_EM_ED();
      if (this.businessUnitName === 'EM' || this.businessUnitName === 'EV' || this.businessUnitName === 'ED') {
        this.generateEMEVRequestData();
      }
      else if (this.businessUnitName === 'PM') {
        this.generatePMRequestData();
      }
      this.requestApi.basf_payer = this.formData['basfPayer'];
      this.requestApi.pricing_Admin_First_Name = '';
      this.requestApi.pricing_Admin_Last_Name = '';
      this.requestApi.pricing_Admin_Email = this.formData['pricingAdminEmail'];
      this.requestApi.userDetails = this.generateUserDetails();
      this.requestApi.created_by = Number(sessionStorage.getItem('userid'));
      this.requestApi.distributor_business_unit = Number(this.businessUnit);
      if (this.formMode == 'Edit') {
        this.requestApi.distributor_id = this.distributorData['distributor_id'];
      }
      this.saveDistributorAPICall();
    }
  }

  saveDistributorAPICall() {
    this._service.submitDistributorData(this.requestApi).subscribe(response => {
      this.spinner.show();
      if (!response['isError']) {
        this._service.displayToastr(response);
        this.reLoadGrid.emit(true);
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._service.displayToastr(response);
      }
    })
  }

  saveDistributorUsertoThreeIamCall(rowData) {
    this._service.saveDistributorUsertoThreeIamCall(rowData).subscribe((response: any) => {
      this.spinner.show();
      if (!response['isError']) {
        this._service.displayToastr(response);
        this.spinner.hide();
        this.checkUserExists(rowData.email, rowData, response['response'].refId);

      } else {

        this.spinner.hide();
        this._service.displayToastr(response);

      }
    })
  }
  generateEMEVRequestData() {
    this.requestApi.products = this.generateProductsObj();
    if (this.businessUnitName == 'ED') {
      this.requestApi.industries = this.generateIndustriesObj();
      this.requestApi.salesReps = this.generateEDSalesRepObj();
    } else {
      this.requestApi.salesReps = this.generateSalesRepObj();
    }
    this.formData['dist_contact_price_support_email'] = (this.dist_contact_price_support_email != undefined && this.dist_contact_price_support_email.length > 0) ? this.dist_contact_price_support_email.join(",") : '';
    this.requestApi.distributor_contact_for_price_support_first_name = '';
    this.requestApi.distributor_contact_for_price_support_last_name = '';
    this.requestApi.distributor_contact_for_price_support_email = this.formData['dist_contact_price_support_email'];
    if (this.businessUnit == 1 || this.businessUnit == 4) {
      this.requestApi.salesSupport = this.generateSalesSupportObj();
    }
  }

  generateSalesSupportObj() {
    let salesSupport = [];
    this.selectedSalesSupport.forEach(am => {
      let salesSupportObj = {
        "distributor_sales_support_id": 0,
        "sales_support_id": am.user_id,
        "distributor_id": 0,
        "user_id": am.user_id,
        "basf_user_id": am.basf_user_id,
        "email": am.email,
        "sbu_id": am.request_master_id
      };
      if (this.formMode == 'Edit') {
        let fSalesSupport = this.salesSupportUsers.find(ss => (ss.sales_support_id == am.user_id && ss.sbu_id == am.request_master_id));
        if (fSalesSupport) {
          salesSupport.push(JSON.parse(JSON.stringify(fSalesSupport)));
        } else {
          salesSupportObj.distributor_id = this.distributorData['distributor_sold_to_id'];
          salesSupport.push(JSON.parse(JSON.stringify(salesSupportObj)));
        }
      } else {
        salesSupport.push(JSON.parse(JSON.stringify(salesSupportObj)));
      }
    });
    return salesSupport;
  }

  generatePMRequestData() {
    this.requestApi.products = [];
    this.requestApi.salesReps = this.generatePMSalesRepObj();
    this.formData['dist_product_manager_email'] = (this.dist_product_manager_email != undefined && this.dist_product_manager_email.length > 0) ? this.dist_product_manager_email.join(",") : '';
    this.requestApi.distributor_contact_for_price_support_first_name = '';
    this.requestApi.distributor_contact_for_price_support_last_name = '';
    this.requestApi.distributor_contact_for_price_support_email = this.formData['dist_product_manager_email'];
  }

  generateFormData() {
    this.formData['distributorName'] = this.distributorData['distributor_name'];
    this.formData['distributorCity'] = this.distributorData['distributor_city'];
    if (this.businessUnitName == 'EM' || this.businessUnitName == 'ED') {
      this.formData['distributorState'] = this.distributorData['distributor_state'];
    }
    this.selectedOrganisation = this.organisationDD.find(o => o.request_master_id == this.distributorData['sales_org_id']);
    this.generateCountry();
    this.selectedCountry = this.countryDD.find(c => c.country_name == this.countryName);
    this.selectedDistribution = this.distributionChannelDD.find(d => d.request_master_id === this.distributorData['distributor_channel_id']);
    this.generateSelectedSBUS();
    this.generateSelectedCustomers();
    this.formData['soldTo'] = this.distributorData['distributor_sold_to_name'];
    this.formData['basfPayer'] = this.distributorData['basf_payer'];
    this.formData['pricingAdminFName'] = this.distributorData['pricing_Admin_First_Name'];
    this.formData['pricingAdminLName'] = this.distributorData['pricing_Admin_Last_Name'];
    this.pricingAdminEmail = this.distributorData['pricing_Admin_Email'] != '' ? this.distributorData['pricing_Admin_Email'].split(",") : [];
    this.prePopulateShipToDD();
    if ((this.businessUnitName === 'EM' || this.businessUnitName === 'EV' || this.businessUnitName === 'ED')) {
      this.generateform_EV_EM_ED();
    } else {
      this.formData['dist_product_manager_fname'] = this.distributorData['distributor_contact_for_price_support_first_name'];
      this.formData['dist_product_manager_lname'] = this.distributorData['distributor_contact_for_price_support_last_name'];
      this.dist_product_manager_email = this.distributorData['distributor_contact_for_price_support_email'] != '' ? this.distributorData['distributor_contact_for_price_support_email'].split(",") : [];
      this.generateSelectedSalesRepFor_Non_EV_EM();
    }
    if ((this.businessUnitName === 'EM' || this.businessUnitName === 'ED') && this.salesSupportUsers.length) {
      this.generateSelectedSalesSupportFor_EM_ED();
    }
  }

  generateform_EV_EM_ED() {
    this.generateSelectedProductsFor_EV_EM_ED();
    this.prePopulateIndustryAndSalesReps();
    if (this.businessUnitName == 'ED') {
      this.prePopulateRegionAndSalesReps();
    }
    this.formData['dist_contact_price_support_fname'] = this.distributorData['distributor_contact_for_price_support_first_name'];
    this.formData['dist_contact_price_support_lname'] = this.distributorData['distributor_contact_for_price_support_last_name'];
    if (this.businessUnitName === 'EM' || this.businessUnitName === 'ED') {
      let ar: any[] = this.adminAndPricingSupportMappings;
      ar.forEach(item => {
        this.pricingAdminEmailbyInd[item.industryId] = item.pricingAdminEmail != '' ? item.pricingAdminEmail.split(',') : [];
        this.dist_product_manager_emailbyInd[item.industryId] = item.distPriceSupportEmail != '' ? item.distPriceSupportEmail.split(',') : [];
      });
    }
    this.dist_contact_price_support_email = this.distributorData['distributor_contact_for_price_support_email'] != '' ? this.distributorData['distributor_contact_for_price_support_email'].split(",") : [];
  }

  generateSelectedSBUS() {
    let selSBUS = [];
    this.sbuData.filter(sbu => {
      let selSBU = this.sbuDD.find(s => s.request_master_id == sbu.distributor_sbu_id);
      if (selSBU) {
        selSBUS.push(selSBU);
      }
    });
    this.selectedSBUS = JSON.parse(JSON.stringify(selSBUS));
  }

  generateSelectedCustomers() {
    let selCustomers = [];
    this.customerData = this.getUniqueListBy(this.customerData, 'customer_name');
    this.customerData.filter(cust => {
      let selCustomer = this.customersDD.find(s => s.customer_id == cust.customer_id);
      if (selCustomer) {
        selCustomers.push(selCustomer);
      }
    });
    this.selectedCustomers = JSON.parse(JSON.stringify(selCustomers));
  }

  generateSelectedProductsFor_EV_EM_ED() {
    let selProds = [];
    this.productsData.filter(prod => {
      let selProd = this.productsDD.find(p => p.product_id == prod.product_id);
      if (selProd) {
        selProds.push(selProd);
      }
    });
    this.selectedProducts = JSON.parse(JSON.stringify(selProds));
  }

  generateSelectedSalesRepFor_Non_EV_EM() {
    let salesRepPM = [];
    this.salesRepData.filter(sr => {
      let selSalesRepPM = this.salesManagersDD.find(am => am.user_id == sr.user_id);
      if (selSalesRepPM) {
        salesRepPM.push(selSalesRepPM);
      }
    });
    this.selectedSalesRep = JSON.parse(JSON.stringify(salesRepPM));
  }

  generateSelectedSalesSupportFor_EM_ED() {
    let salesSupports = [];
    this.salesSupportUsers.filter(ss => {
      let selSalesSupport = this.salesManagersDD.find(p => p.user_id == ss.sales_support_id);
      if (selSalesSupport) {
        let sbu = this.sbuDD.find(x => x.request_master_id == ss.sbu_id);
        selSalesSupport['sbuName'] = sbu.value
        selSalesSupport['request_master_id'] = sbu.request_master_id
        salesSupports.push(JSON.parse(JSON.stringify(selSalesSupport)));
      }
    });
    this.selectedSalesSupport = JSON.parse(JSON.stringify(salesSupports));
    this.selectedSalesSupport.forEach(x => {
      if (!this.selectedSalesSupportbyBU[x.request_master_id]) {
        let selUsers = [];
        let tempSelUsers = [];
        tempSelUsers = this.selectedSalesSupport.filter(y => y.request_master_id === x.request_master_id);
        tempSelUsers.forEach(user => {
          let availUser = this.salesManagersDD.find(am => am.basf_user_id === user.basf_user_id);
          if (availUser) {
            selUsers.push(availUser);
          }
        })
        this.selectedSalesSupportbyBU[x.request_master_id] = JSON.parse(JSON.stringify(selUsers));
      }
    });
  }

  generateEmailsByindustryID_for_EM_ED() {
    let genObj: any[] = [];
    for (let ind of this.selectedIndustries) {
      let obj = {
        distributor_Support_Mapping_id: 0,
        industryId: ind.request_master_id,
        pricingAdminEmail: null,
        distPriceSupportEmail: null
      }
      obj.pricingAdminEmail = this.pricingAdminEmailbyInd[ind.request_master_id] ? this.pricingAdminEmailbyInd[ind.request_master_id].join(',') : '';
      obj.distPriceSupportEmail = this.dist_product_manager_emailbyInd[ind.request_master_id] ? this.dist_product_manager_emailbyInd[ind.request_master_id]!.join(',') : '';
      if (obj.pricingAdminEmail == '' && obj.distPriceSupportEmail == '') {
        continue;
      }
      genObj.push(JSON.parse(JSON.stringify(obj)));
    }
    return genObj;
  }

  prePopulateIndustryAndSalesReps() {
    let selIndustries = [];
    this.salesRepData.filter(i => {
      let selIndustry = this.industriesDD.find(s => s.request_master_id == i.industry_id);
      if (selIndustry) {
        selIndustries.push(selIndustry);
      }
    });

    this.selectedIndustries = JSON.parse(JSON.stringify(selIndustries));
    this.selectedIndustries.forEach((item) => {
      this.dist_product_manager_emailbyInd[item.request_master_id] = [];
      this.pricingAdminEmailbyInd[item.request_master_id] = [];

    });
    if (this.businessUnitName != 'ED') {
      this.accountManagerDUP = [];
      this.salesRepData.forEach((sd, _index) => {
        let salesRepDetailsFound = this.accountManagers.some(am => am.industry_id === sd.industry_id);
        if (!salesRepDetailsFound) {
          let am = {
            salesRepUserId: sd.user_id,
            salesRep: sd.basf_user_id,
            salesRepEmail: sd.email,
            industry_id: sd.industry_id
          };
          this.accountManagers.push(am);
          let fAccountManager = this.accountManagersDD.find(am2 => am2.user_id === sd.user_id);
          if (fAccountManager) {
            this.accountManagerDUP.push(fAccountManager);
          }
        } else {
          this.accountManagers = this.accountManagers.filter(am => am.industry_id !== sd.industry_id);
        }
      });
      this.dupAccountManagers['manager'] = JSON.parse(JSON.stringify(this.accountManagerDUP));
    }
  }

  prePopulateRegionAndSalesReps() {
    let selRegions = [];
    this.salesRepData.filter(i => {
      let selRegion = this.distributionRegionDD.find(s => s.request_master_id == i.distributor_region_id);
      if (selRegion) {
        selRegions.push(selRegion);
      }
    });

    this.selectedDistributorRegions = JSON.parse(JSON.stringify(selRegions));
    this.accountManagerDUP = [];
    this.salesRepData.forEach((sd, _index) => {
      if (sd.distributor_region_id > 0) {
        let salesRepDetailsFound = this.accountManagers.some(am => am.region_id === sd.distributor_region_id);
        if (!salesRepDetailsFound) {
          let am = {
            salesRepUserId: sd.user_id,
            salesRep: sd.basf_user_id,
            salesRepEmail: sd.email,
            region_id: sd.distributor_region_id
          };
          this.accountManagers.push(am);
          let fAccountManager = this.accountManagersDD.find(am2 => am2.user_id === sd.user_id);
          if (fAccountManager) {
            this.accountManagerDUP.push(fAccountManager);
          }
        } else {
          this.accountManagers = this.accountManagers.filter(am => am.region_id !== sd.distributor_region_id);
        }
      }
    });
    this.dupAccountManagers['manager'] = JSON.parse(JSON.stringify(this.accountManagerDUP));
  }

  prePopulateShipToDD() {
    this.shipToData.forEach(st => {
      this.shipToName = String(st.distributor_ship_to_Full_name).substring(String(st.distributor_ship_to_Full_name).indexOf('-') + 1, String(st.distributor_ship_to_Full_name).lastIndexOf(st.distributor_ship_to_city) - 1);
      this.shipToId = st.distributor_ship_to_name;
      this.shipToCity = st.distributor_ship_to_city;
      this.shipToAddress = st.distributor_ship_to_address;
      this.addShipTo();
    })
  }

  generateCountry() {
    let country = 0;
    this.selectedCountry = this.countryDD.find(c => c.country_code === this.selectedOrganisation.value.slice(0, 2));
    this.countryName = this.selectedCountry.country_name;
    this.productsDD = this.masterAPIResp.products.filter(p => p.country_Id === this.selectedCountry.country_id);
    return country;
  }

  generateSalesRes() {
    let salesReps = [];
    this.accountManagers.forEach(am => {
      if (am.salesRep !== '' && am.salesRepEmail !== '') {
        let salesRepObj = {
          "sales_rep_email_id": 0,
          "sales_rep_name": am.salesRep,
          "sales_rep_email": am.salesRepEmail,
          "distributor_id": 0,
          "industry_id": am.industry_id
        };
        salesReps.push(salesRepObj);
      }
    });
    return salesReps;
  }

  generateUserDetails() {
    let users = [];
    this.orgUsersData.forEach(user => {
      if (this.formType === 'New') {
        let userObj = {
          "distributor_user_id": this.getuserDetailsbyemailId(user.email),
          "first_name": user.first_name,
          "last_name": user.last_name,
          "email": user.email,
          "phone": 0,
          "created_by": Number(sessionStorage.getItem('userid')),
          "is_Admin": this.checkAdminUser(user.email),
          "RefId": user.RefId
        };
        users.push(userObj);
      } else {
        user['is_Admin'] = this.checkAdminUser(user.email);
        users.push(user)
      }
    });
    return users;
  }

  checkAdminUser(email) {
    let emailFound = this.pricingAdminEmail ? this.pricingAdminEmail.find(pae => pae === email) : false;
    if (emailFound) {
      return true;
    }
    let emailFound1 = this.dist_contact_price_support_email ? this.dist_contact_price_support_email.find(dcpse => dcpse === email) : false;
    if (emailFound1) {
      return true;
    }
    let emailFound2 = this.dist_product_manager_email ? this.dist_product_manager_email.find(dpme => dpme === email) : false;
    if (emailFound2) {
      return true;
    }
    return false;
  }

  generateSBUObj() {
    let sbuData = [];
    this.selectedSBUS.forEach(sbu => {
      let sbuObj = {
        "distributor_sbu_mapping_id": 0,
        "distributor_sbu_id": sbu.request_master_id,
        "distributor_id": 0
      }
      if (this.formMode == 'Edit') {
        let fSBU = this.sbuData.find(s => s.distributor_sbu_id == sbu.request_master_id);
        if (fSBU) {
          sbuData.push(fSBU);
        } else {
          sbuObj.distributor_id = this.distributorData['distributor_id'];
          sbuData.push(sbuObj);
        }
      } else {
        sbuData.push(sbuObj);
      }
    });
    return sbuData;
  }

  generateCustomerbj() {
    let customerData = [];
    if (this.selectedCustomers.length > 0) {
      this.selectedCustomers.forEach(cust => {
        let customerObj = {
          "distributor_account_mapping_id": 0,
          "distributor_account_id": cust.customer_id,
          "distributor_id": 0
        }
        if (this.formMode == 'Edit') {
          let fSBU = this.customerData.find(s => s.distributor_account_id == cust.customer_id);
          if (fSBU) {
            customerData.push(fSBU);
          } else {
            customerObj.distributor_id = this.distributorData['distributor_id'];
            customerData.push(customerObj);
          }
        } else {
          customerData.push(customerObj);
        }
      });
    }
    return customerData;
  }

  generateProductsObj() {
    let productData = [];
    if (!this.selectedProducts) {
      return productData;
    }
    this.selectedProducts.forEach(product => {
      let prodObj = {
        "distributor_soldto_product_mapping_id": 0,
        "product_id": product.product_id,
        "distributor_sold_to_id": 0
      };
      if (this.formMode == 'Edit') {
        let fProduct = this.productsData.find(p => p.product_id == product.product_id);
        if (fProduct) {
          productData.push(fProduct);
        } else {
          prodObj.distributor_sold_to_id = this.distributorData['distributor_sold_to_id'];
          productData.push(prodObj);
        }
      } else {
        productData.push(prodObj);
      }
    });
    return productData;
  }

  validateAccountManagers() {
    let isValid = true;
    if (!this.accountManagers) {
      this.toastr.error("Account manager is a mandate field");
      isValid = false;
    }
    this.accountManagers.forEach(am => {
      if (am == undefined || am.salesRepUserId == 0) {
        this.toastr.error("Account manager is a mandate field");
        isValid = false;
      }
    });
    return isValid;
  }

  generateIndustriesObj() {
    let industryData = [];
    this.selectedIndustries.forEach(am => {
      let industryObj = {
        "distributor_sales_rep_mapping_id": 0,
        "distributor_id": 0,
        "industry_id": am.request_master_id,
        "region_id": 0,
        "user_id": 0
      }
      if (this.formMode == 'Edit') {
        let fIndustry = this.industriesDD.find(s => s.industry_id == am.request_master_id);
        if (fIndustry) {
          industryData.push(fIndustry);
        } else {
          industryObj.distributor_id = this.distributorData['distributor_id'];
          industryData.push(industryObj);
        }
      } else {
        industryData.push(industryObj);
      }
    });
    return industryData;
  }

  generateEDSalesRepObj() {
    let salesRepData = [];
    this.accountManagers.forEach(am => {
      let salesRepObj = {
        "distributor_sales_rep_mapping_id": 0,
        "distributor_id": 0,
        "user_id": am.salesRepUserId,
        "region_id": am.region_id
      };
      if (this.formMode == 'Edit') {
        let fSalesRep = this.salesRepData.find(s => s.user_id == am.salesRepUserId && s.region_id == am.region_id);
        if (fSalesRep) {
          salesRepData.push(fSalesRep);
        } else {
          salesRepObj.distributor_id = this.distributorData['distributor_id'];
          salesRepData.push(salesRepObj);
        }
      } else {
        salesRepData.push(salesRepObj);
      }
    });
    return salesRepData;
  }

  generateSalesRepObj() {
    let salesRepData = [];
    this.accountManagers.forEach(am => {
      let salesRepObj = {
        "distributor_sales_rep_mapping_id": 0,
        "distributor_id": 0,
        "user_id": am.salesRepUserId,
        "industry_id": am.industry_id,
        "region_id": am.region_id
      };
      if (this.formMode == 'Edit') {
        let fSalesRep = this.businessUnitName == 'Ed' ? this.salesRepData.find(s => s.user_id == am.salesRepUserId && s.region_id == am.region_id) : this.salesRepData.find(s => s.user_id == am.salesRepUserId && s.industry_id == am.industry_id);
        if (fSalesRep) {
          salesRepData.push(fSalesRep);
        } else {
          salesRepObj.distributor_id = this.distributorData['distributor_id'];
          salesRepData.push(salesRepObj);
        }
      } else {
        salesRepData.push(salesRepObj);
      }
    });
    return salesRepData;
  }

  generatePMSalesRepObj() {
    let salesRepData = [];
    this.selectedSalesRep.forEach(ss => {
      let salesRepObj = {
        "distributor_sales_rep_mapping_id": 0,
        "distributor_id": 0,
        "user_id": ss.user_id,
        "industry_id": 0
      };
      if (this.formMode == 'Edit') {
        let fSalesRep = this.salesRepData.find(s => s.user_id == ss.user_id);
        if (fSalesRep) {
          salesRepData.push(fSalesRep);
        } else {
          salesRepObj.distributor_id = this.distributorData['distributor_id'];
          salesRepData.push(salesRepObj);
        }
      } else {
        salesRepData.push(salesRepObj);
      }
    });
    return salesRepData;
  }

  generateSoldToObj() {
    let shipToObj = {
      "distributor_sold_to_id": 0,
      "distributor_sold_to_name": this.formData['soldTo'],
      "sold_to_sap_id": 0,
      "distributorShipTos": this.generateShipToObj()
    }
    if (this.formMode == 'Edit') {
      shipToObj.distributor_sold_to_id = this.distributorData['distributor_sold_to_id'];
    }
    return shipToObj;
  }

  generateShipToObj() {
    let shipToData = [];
    if (this.shipTo) {
      this.shipTo.forEach(ship => {
        let shipToObj = {
          "distributor_ship_to_id": 0,
          "distributor_ship_to_name": ship.shipToId,
          "distributor_ship_to_city": ship.shipToCity,
          "distributor_ship_to_Full_name": ship.shipToFullName,
          "distributor_ship_to_Address": ship.shipToAddress,
          "ship_to_sap_id": 0
        }
        if (this.formMode == 'Edit') {
          let fShipTo = this.shipToData.find(st => st.distributor_ship_to_Full_name == ship.shipToFullName);
          if (fShipTo) {
            shipToData.push(fShipTo);
          } else {
            shipToObj['distributor_sold_to_id'] = this.distributorData['distributor_sold_to_id'];
            shipToData.push(shipToObj);
          }
        } else {
          shipToData.push(shipToObj);
        }
      });
    }
    return shipToData;
  }

  generateSalesAccts() {
    let salesAccts = [];
    this.selectedSalesRep.forEach(rep => {
      let salesAcctObj = {
        "sales_account_email_id": rep.sales_rep_email_id,
        "sales_account_name": rep.sales_rep_name,
        "sales_account_email": rep.sales_account_email,
        "distributor_id": 0,
        "industry_id": 0
      }
      salesAccts.push(salesAcctObj);
    });
    return salesAccts;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }

  checkDistributorValidation() {
    let validDistributorName = this.checkValidDistributorName();
    let validDistributorOrganisation = this.checkValidDistributorOrganisation()
    let validDistributorCity = this.checkValidDistributorCity();
    let validDistributorState = true;
    if (this.businessUnitName == 'EM' || this.businessUnitName == 'ED') {
      validDistributorState = this.checkValidDistributorState();
    }
    let validDistributorCountry = this.checkValidDistributorCountry();
    let validDistributorChannel = this.checkValidDistributorChannel();
    let validDistributorSBU = this.checkValidDistributorSBU();
    let validDistributorSoldto = this.checkValidDistributorSoldto();
    let validDistributorBASFPayer = this.checkValidDistributorBASFPayer();
    let validDistributorIndustry = true;
    if (this.businessUnitName != 'PM') {
      validDistributorIndustry = this.checkValidDistributorIndustry();
    }
    let validDistributorRegion = true;
    if (this.businessUnitName == 'ED') {
      validDistributorRegion = this.checkValidDistributorRegion();
    }
    let validShipTo = this.checkShipTo();
    let validAccountManager = true;
    if (this.businessUnitName == 'EM' || this.businessUnitName == 'ED') {
      validAccountManager = this.validateAccountManagers();
    }
    return validDistributorName && validDistributorOrganisation && validDistributorCity && validDistributorCountry && validDistributorChannel && validDistributorSBU
      && validDistributorSoldto && validDistributorBASFPayer && validDistributorIndustry && validShipTo && validDistributorState && validDistributorRegion && validAccountManager;
  }

  checkValidDistributorName() {
    if (!this.formData['distributorName']) {
      this.toastr.error("Distributor Name required", "Error!");
      return false;
    } else {
      return true;
    }
  }
  checkValidDistributorOrganisation() {
    if (this.selectedOrganisation != null) {
      return true;
    } else {
      this.toastr.error("Sales Organisation required", "Error!");
      return false;
    }
  }
  checkValidDistributorCity() {
    if (!this.formData["distributorCity"]) {
      this.toastr.error("Distributor City required", "Error!");
      return false;
    } else {
      return true;
    }
  }
  checkValidDistributorState() {
    if (!this.formData["distributorState"]) {
      this.toastr.error("Distributor State required", "Error!");
      return false;
    } else {
      return true;
    }
  }
  checkValidDistributorCountry() {
    if (this.countryName != null) {
      return true;
    } else {
      this.toastr.error("Distribution Country required", "Error!");
      return false;
    }
  }
  checkValidDistributorChannel() {
    if (this.selectedDistribution != null) {
      return true;
    } else {
      this.toastr.error("Distribution Channel required", "Error!");
      return false;
    }
  }
  checkValidDistributorSBU() {
    if (this.selectedSBUS != null) {
      return true;
    } else {
      this.toastr.error("Select SBU", "Error!");
      return false;
    }
  }
  checkValidDistributorSoldto() {
    if (this.formData["soldTo"] != "") {
      return true;
    } else {
      this.toastr.error("Sold to required", "Error!");
      return false;
    }
  }

  checkValidDistributorBASFPayer() {
    if (this.formData["basfPayer"] != "") {
      return true;
    } else {
      this.toastr.error("BASF Payer required", "Error!");
      return false;
    }
  }
  checkValidDistributorProductMapping() {
    if (this.selectedProducts != null || this.businessUnitName === 'PM') {
      return true;
    } else {
      this.toastr.error("Product Mapping required", "Error!");
      return false;
    }
  }

  checkValidDistributorIndustry() {
    if (this.selectedIndustries != null && this.selectedIndustries.length > 0) {
      return true;
    } else {
      this.toastr.error("Industries required", "Error!");
      return false;
    }
  }
  checkValidDistributorRegion() {
    if (this.selectedDistributorRegions != null && this.selectedDistributorRegions.length > 0) {
      return true;
    } else {
      this.toastr.error("Regions required", "Error!");
      return false;
    }
  }
  checkShipTo() {
    if (this.shipTo != null || this.businessUnitName === 'PM' || this.businessUnitName === 'EM' || this.businessUnitName === 'ED') {
      return true;
    } else {
      this.toastr.error("Add ShipTo's", "Error!");
      return false;
    }
  }

  validateEmailAddress(event, type) {
    return this.checkEmailValidation(event.value, type);
  }

  checkEmailValidation(emailAddress, type) {
    if (emailAddress == '') {
      return false;
    } else {
      let reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
      if (!reg.test(emailAddress)) {
        this.toastr.error("Invalid Email");
        switch (type) {
          case 1: this.pricingAdminEmail.pop(); break;
          case 2: this.dist_contact_price_support_email.pop(); break;
          case 3: this.dist_product_manager_email.pop(); break;
          default: break;
        }
      }
    }
  }

}
